<template>
    <div class='tgju-widgets-row dashboard-widgets-technical-list dash-full-height'>
        <div class="mobile-app-tabs" v-if="is_home">
            <router-link :class="'mobile-app-tabs-items ' + (item.name == 'RetailViews' ? 'app-active-tab' : '')"
                v-for="(item, index) in example_market" :key="index"
                :to="{ name: item.name, params: { market_type: item.label } }">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>{{ item.title }}</h3>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="market-grid-home">
            <slot v-if="is_home">
                <span v-for="(item, index) in static_main" :key="index" @click="getCurrentRetailTab(index, item.id)">
                    <div class="market-grid-home-item">
                        <img :src="item.image">
                        <span> {{ item.label }} </span>
                        <p>{{ item.info }}</p>
                    </div>
                </span>
            </slot>
        </div>

        <div class="market-slider-tabs-box" v-if="IsRetailList">
            <router-link :to="{ name: 'RetailViews' }" class="mobile-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <div class="market-slider-tabs">
                <template>
                    <v-card>
                        <v-tabs v-model="tab" center-active>
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab v-for="(item, index) in static_main" :key="index"
                                @click="getCurrentRetailTab(index, item.id)">
                                <i :class="item.icon"></i>
                                <span>{{ item.label }}</span>
                            </v-tab>
                        </v-tabs>
                    </v-card>
                </template>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>
    
<script>
import static_main from "@/assets/retail_static";
import vSelect2 from 'vue-select';
import moment from "moment-jalaali";
Vue.component('v-select2', vSelect2);


// این ویو برای ماژول بازار استفاده میشود
// این ویو در موبایل قرار است فقط نمایش در بیاید
export default {
    name: 'RetailViews',
    data: function () {
        return {
            tab: this.$store.state.currentTabMarket,
            is_home: true,
            IsRetailList: false,
            static_main,
            example_market: [
                {
                    title: 'بازارهای داخلی',
                    label: 'local-markets',
                    name: 'MarketViews'
                },
                {
                    title: 'بازارهای جهانی',
                    label: 'global-markets',
                    name: 'MarketViews'
                },
                {
                    title: 'بازارهای خرده فروشی',
                    label: '',
                    name: "RetailViews"
                }
            ]
        }
    },
    destroyed() {

    },
    beforeMount() {

    },
    mounted() {
        this.is_home = !window.location.href.includes('list');
        this.IsRetailList = window.location.href.includes('list') && !this.$route.path.includes('subCategory');
    },
    methods: {
        getCurrentRetailTab(tab, id) {
            this.$store.commit('setCurrentTabMarket', tab);
            this.$router.push({ name: "RetailList", params: { retail_id: id } });
        }
    },
    computed: {

    },
    watch: {
    }
}
</script>
    